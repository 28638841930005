import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import Script from 'next/script';
import {
    FieldsValidationError,
    type FieldErrors,
    type StringFieldDefinition,
    validateCardFields,
    type CardFields,
    cardFields as initialCardFields
} from '@wilm/common';
import toast from 'react-hot-toast';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import AccountAddresses from 'components/commercetools-ui/organisms/checkout/components/steps/sections/addresses/components/account-addresses';
import useMappers from 'components/commercetools-ui/organisms/checkout/components/steps/sections/addresses/hooks/useMappers';
import type { Address } from 'components/commercetools-ui/organisms/checkout/components/steps/sections/addresses/types';
import CreditCardFields from 'components/cybersource/credit-cart-fields';
import type { MessageModalProps } from 'components/sales-link/organisms/message-modal';
import MessageModal from 'components/sales-link/organisms/message-modal';
import { useFormat } from 'helpers/hooks/useFormat';
import { addInitialPaymentToGetTokenContext, tokenizeUser } from 'helpers/utils/payment';
import { useCybersourceContext } from 'providers/cybersource';
import { useAccount } from 'frontastic';
import useCybersourceSettings from 'frontastic/hooks/useCybersourceSettings';

interface Currencies {
    value: string;
    label: string;
}

const PaymentAdd = () => {
    const router = useRouter();
    const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
    const { setupMicroform, getTransientToken } = useCybersourceContext();
    const cybersourceSettings = useCybersourceSettings();
    const { billingAddresses, mutateAccount, defaultBillingAddress } = useAccount();

    const [paymentIsLoading, setPaymentIsLoading] = useState(false);
    const [cardFields, setCardFields] = useState(initialCardFields);
    const [cardFieldsErrors, setCardFieldsErrors] = useState<FieldErrors>({});
    const [billingAddress, setBillingAddress] = useState({} as Address);
    const [billingNotSelected, setBillingNotSelected] = useState(false);
    const [currency, setCurrency] = useState<string>('GBP');
    const [currencyInvalid, setCurrencyInvalid] = useState<boolean>(false);
    const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);
    const [messageModalData, setMessageModalData] = useState({} as MessageModalProps['data']);
    const { accountAddressToAddress } = useMappers();

    const currencies = useMemo<Currencies[]>(
        () => [
            { label: '-- Select --', value: '' },
            { label: 'HKD', value: 'HKD' },
            { label: 'USD', value: 'USD' },
            { label: 'GBP', value: 'GBP' },
            { label: 'EUR', value: 'EUR' },
            { label: 'SGD', value: 'SGD' },
            { label: 'MYR', value: 'MYR' }
        ],
        []
    );

    useEffect(() => {
        void createPaymentToGetTokenContext(0, currency);
    }, [currency]);

    useEffect(() => {
        if (defaultBillingAddress) {
            const address = accountAddressToAddress(defaultBillingAddress);
            setBillingAddress(address);
        }
    }, [defaultBillingAddress]);

    const openModalWithError = useCallback(
        (message: string) => {
            setMessageModalData({
                message,
                type: 'error',
                showCloseButton: false,
                showCTAButton: true,
                ctaButtonText: formatPaymentMessage({ id: 'close', defaultMessage: 'Close' }),
                ctaButtonVariant: 'warning',
                ctaButtonAction: () => {
                    router.push('/account?hash=payment');
                    setMessageModalIsOpen(false);
                }
            });
            setMessageModalIsOpen(true);
        },
        [formatPaymentMessage, router]
    );

    const createPaymentToGetTokenContext = useCallback(
        async (centAmount: number, currencyCode: string) => {
            console.info('---> createPaymentToGetTokenContext', centAmount, currencyCode);
            setPaymentIsLoading(true);
            const paymentResult = await addInitialPaymentToGetTokenContext(centAmount, currencyCode);

            if (paymentResult.isError) {
                openModalWithError(
                    `${formatPaymentMessage({
                        id: 'customer.token.creation.failed',
                        defaultMessage: 'Token Creation failed'
                    })}`
                );
                setPaymentIsLoading(false);
                return;
            }

            const payment = paymentResult.payment;
            setupMicroform(payment.signature, payment.clientLibrary, payment.clientLibraryIntegrity);
            setPaymentIsLoading(false);
        },
        [setupMicroform, openModalWithError, formatPaymentMessage]
    );

    const handleSaveCardButton = useCallback(async () => {
        let hasErrors = false;

        const fieldsErrors = validateCardFields(cardFields);
        if (Object.keys(fieldsErrors).length) {
            setCardFieldsErrors(fieldsErrors);
            setPaymentIsLoading(false);
            hasErrors = true;
        }

        let token = '';
        try {
            token = await getTransientToken({ expirationMonth: cardFields.expiryMonth.value, expirationYear: cardFields.expiryYear.value });
            console.info('---> transientToken', token);
        } catch (error) {
            console.info('---> getTransientToken error', error);

            if (error instanceof FieldsValidationError) {
                setCardFieldsErrors(error.errors);
                hasErrors = true;
            } else {
                openModalWithError(
                    `${formatPaymentMessage({
                        id: 'customer.token.creation.failed',
                        defaultMessage: 'Token Creation failed'
                    })}`
                );
            }
        }

        if (!billingAddress.addressId) {
            setBillingNotSelected(true);
            hasErrors = true;
        }

        if (!currency) {
            setCurrencyInvalid(true);
            hasErrors = true;
        } else {
            setCurrencyInvalid(false);
        }

        if (hasErrors) {
            return;
        }

        const accountResponse = await tokenizeUser(token, billingAddress.addressId, currency);

        if (!accountResponse) {
            toast.error(
                `${formatPaymentMessage({
                    id: 'customer.token.creation.failed',
                    defaultMessage: 'Token Creation failed'
                })}`
            );
        } else {
            await mutateAccount();
        }
        router.push('/account?hash=payment');
    }, [billingAddress, currency, mutateAccount, cardFields, getTransientToken, formatPaymentMessage]);

    const closeMessageModal = () => {
        setMessageModalIsOpen(false);
    };

    return (
        <>
            {cybersourceSettings.flexFormLink && (
                <>
                    {!cybersourceSettings.useDynamicFlexFormLink && (
                        <Script id="cs-library" src={cybersourceSettings.flexFormLink} strategy="lazyOnload" data-loaded="true" />
                    )}

                    <div className="ml-0 mt-20 lg:ml-44 lg:mt-40">
                        <div className="mt-24 px-16 md:mt-0 md:px-24 lg:px-0">
                            <Typography as="h2" className="text-primary-black md:text-22 lg:text-24">
                                {formatPaymentMessage({
                                    id: 'add.card',
                                    defaultMessage: 'Add new card'
                                })}
                            </Typography>
                        </div>
                    </div>
                    {billingAddresses.length === 0 && (
                        <div className="ml-0 lg:ml-44 ">
                            <div>
                                {' '}
                                {formatPaymentMessage({
                                    id: 'store.card',
                                    defaultMessage: 'To store a card you need a billing address first'
                                })}
                            </div>
                            <Button variant="primary" size="s" className="mt-20 rounded-md px-16 py-8 xl:mt-12">
                                <Link link={`/account/?hash=addresses&id=address-add`}>
                                    <Typography>
                                        {formatPaymentMessage({ id: 'address.add', defaultMessage: 'Add new address' })}
                                    </Typography>
                                </Link>
                            </Button>
                        </div>
                    )}
                    {currencies?.length > 0 && billingAddresses.length > 0 && (
                        <div className="ml-0 px-16 pt-32 md:max-w-[436px] lg:ml-44 lg:px-0">
                            <div className="col-span-3 mb-16">
                                <Dropdown
                                    name="currency"
                                    items={currencies}
                                    required={true}
                                    className="w-full"
                                    label={formatPaymentMessage({ id: 'stateOrProvince', defaultMessage: 'Currency' })}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                                        setCurrencyInvalid(false);
                                        setCurrency(e.target.value);
                                    }}
                                    defaultValue="GBP"
                                />
                                {currencyInvalid && (
                                    <p className="mt-12 text-left font-body text-sm leading-tight text-input-error">
                                        {formatPaymentMessage({
                                            id: 'card.invalid.currency',
                                            defaultMessage: 'Select a card currency'
                                        })}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    {billingAddresses.length > 0 && currency && (
                        <>
                            <div className="ml-0 px-16 md:max-w-[436px] lg:ml-44 lg:px-0">
                                <CreditCardFields
                                    paymentIsLoading={paymentIsLoading}
                                    cardFields={cardFields}
                                    cardFieldsErrors={cardFieldsErrors}
                                    setCardField={(field: keyof CardFields, value: string) => {
                                        if (field === 'expiryYear') {
                                            value = '20' + value;
                                        }
                                        setCardFields(prev => ({
                                            ...prev,
                                            [field]: {
                                                ...prev[field],
                                                value
                                            } as StringFieldDefinition
                                        }));
                                    }}
                                />
                            </div>

                            <div className="bg-white px-16 pt-16 lg:px-44 lg:pb-36 lg:pt-16">
                                <div className="mt-20">
                                    <h5 className="text-base">
                                        {formatPaymentMessage({ id: 'billingAddress', defaultMessage: 'Billing Address' })}
                                    </h5>
                                    <AccountAddresses
                                        className="mt-20"
                                        type="billing"
                                        onSelectAddress={address => {
                                            setBillingNotSelected(false);
                                            setBillingAddress(address);
                                        }}
                                        selectedAddress={billingAddress}
                                    />
                                </div>
                                {billingNotSelected && (
                                    <p className="mt-12 text-left font-body text-sm leading-tight text-input-error">
                                        {formatPaymentMessage({
                                            id: 'billing.invalid',
                                            defaultMessage: 'Please choose billing address'
                                        })}
                                    </p>
                                )}
                            </div>

                            <div className="ml-0 mt-20 lg:ml-44 lg:mt-40">
                                <div className="mt-24 px-16 md:mt-0 md:px-24 lg:px-0">
                                    <div className="mt-32 flex">
                                        <Button variant="secondary" className="w-112" onClick={() => router.push('/account#payment')}>
                                            <Typography as="h2" className="text-center text-14 text-primary-black">
                                                {formatPaymentMessage({
                                                    id: 'cancel',
                                                    defaultMessage: 'Cancel'
                                                })}
                                            </Typography>
                                        </Button>

                                        <Button
                                            variant="primary"
                                            className="ml-12 w-112"
                                            loading={paymentIsLoading}
                                            type="submit"
                                            onClick={handleSaveCardButton}
                                        >
                                            {formatPaymentMessage({
                                                id: 'save',
                                                defaultMessage: 'Save'
                                            })}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
            <MessageModal isOpen={messageModalIsOpen} closeModal={closeMessageModal} data={messageModalData} />
        </>
    );
};

export default PaymentAdd;
