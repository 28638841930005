import React, { useEffect, useState } from 'react';
import type { Wishlist as WishlistType } from '@wilm/common';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import { useFormat } from 'helpers/hooks/useFormat';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useWishlist } from 'frontastic';
import WishlistItem from './components/wishlist-item';

interface Props {
    style?: 'boxed';
    showEmpty?: boolean;
}

const Wishlist = ({ style, showEmpty = false }: Props) => {
    const { wishlist } = useBrandSettingsContext();
    const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });
    const { data: wishlistData } = useWishlist();

    const headingClass = style === 'boxed' ? 'mb-20' : '';
    const contanerClass = style === 'boxed' ? 'border-checkout-border border px-30 py-2' : '';

    const [data, setData] = useState<WishlistType | null>(null);

    const [isEmpty, setIsEmpty] = useState(!wishlistData?.lineItems?.length);
    useEffect(() => {
        if (wishlistData) {
            setData(wishlistData);
            setIsEmpty(!wishlistData?.lineItems?.length);
        }
    }, [wishlistData]);

    useEffect(() => {
        if (data && isEmpty) {
            setData(null);
        }
    }, [data, isEmpty]);

    if (!wishlist?.enabled) {
        return null;
    }

    if (!isEmpty) {
        return (
            <div
                className="border-chekout-border border-t bg-white px-16 pt-20 md:px-24 md:py-12 lg:mt-40 lg:border-0 lg:px-20 lg:py-36 xl:px-40"
                data-cy="wishlist-wrapper"
            >
                <h3 className={`text-24 font-bold ${headingClass}`} data-cy="wishlist-title">
                    {formatWishlistMessage({ id: 'wishlist', defaultMessage: 'Wishlist' })}
                </h3>
                <div className={contanerClass} data-cy="wishlist-items-container">
                    {data?.lineItems?.map(lineItem => <WishlistItem key={lineItem.lineItemId} item={lineItem} />)}
                </div>
            </div>
        );
    }

    if (showEmpty) {
        return (
            <div className="border-chekout-border border-t bg-white px-16 pt-20 md:px-24 md:py-12 lg:mt-40 lg:border-0 lg:px-20 lg:py-36 xl:px-40">
                <h3 className={`text-24 font-bold ${headingClass}`}>
                    {formatWishlistMessage({ id: 'wishlist', defaultMessage: 'Wishlist' })}
                </h3>
                {wishlist.emptyMessage && (
                    <p className="mt-20 text-16" data-cy="empty-wishlist-message">
                        {wishlist.emptyMessage}
                    </p>
                )}

                {wishlist.emptyCTALink && wishlist.emptyCTAText && (
                    <Link link={wishlist.emptyCTALink}>
                        <Button className="mt-20" variant="primary">
                            {wishlist.emptyCTAText}
                        </Button>
                    </Link>
                )}
            </div>
        );
    }
};
export default Wishlist;
