import { useEffect, useState } from 'react';
import type { Order } from '@wilm/common';
import { useCart } from 'frontastic';

const useSubscriptionsFetch = () => {
    const { shippingMethods, subscriptionHistory } = useCart();
    const [ordersSubscription, setOrdersSubscription] = useState<Order[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        if (subscriptionHistory) {
            void subscriptionHistory().then(data => {
                setOrdersSubscription(data);
                setLoading(false);
            });
        } else {
            setOrdersSubscription([]);
            setLoading(false);
        }
    }, [subscriptionHistory]);

    return {
        ordersSubscription,
        shippingMethods,
        loading
    };
};

export default useSubscriptionsFetch;
