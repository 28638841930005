import type { FC } from 'react';
import React from 'react';
import type { Order } from '@wilm/common';
import Skeleton from 'react-loading-skeleton';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { calculateAndFormatMembershipStartDate, formatDate } from 'helpers/dateHelpers';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
    order?: Order;
    loading?: boolean;
}

const OrderItem: FC<Props> = ({ order, loading }) => {
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    const orderItems = order?.lineItems?.[0];

    const endDate = order?.custom?.fields?.nextRenewDate;
    const formattedEndDate = endDate && formatDate(endDate);
    const duration = Number(order?.custom?.fields?.duration);
    console.info('---> endDate, duration', endDate, duration);

    const formattedStartDate = endDate && duration && calculateAndFormatMembershipStartDate(endDate, duration);

    console.log(order);

    return (
        <div className="mb-15 w-full border border-input-disabled">
            <div className="grid grid-cols-1 p-15 text-md  sm:grid-cols-2 lg:text-base  xl:p-30">
                <div className="leading-loose">
                    {!loading ? orderItems?.name && <Typography className="font-bold">{orderItems?.name}</Typography> : <Skeleton />}

                    {!loading ? (
                        formattedStartDate && (
                            <div className="flex">
                                <Typography className="font-bold">
                                    {formatOrdersMessage({ id: 'start.date', defaultMessage: 'Start date:' })}
                                </Typography>
                                <Typography className="pl-5">{formattedStartDate}</Typography>
                            </div>
                        )
                    ) : (
                        <Skeleton />
                    )}

                    {!loading ? (
                        formattedEndDate && (
                            <div className="flex">
                                <Typography className="font-bold">
                                    {formatOrdersMessage({ id: 'end.date', defaultMessage: 'End date:' })}
                                </Typography>
                                <Typography className="pl-5">{formattedEndDate}</Typography>
                            </div>
                        )
                    ) : (
                        <Skeleton />
                    )}

                    {!loading ? (
                        order?.state && (
                            <div className="flex">
                                <Typography className="font-bold">
                                    {formatOrdersMessage({ id: 'status', defaultMessage: 'Status:' })}
                                </Typography>
                                <Typography className="pl-5">{order?.state}</Typography>
                            </div>
                        )
                    ) : (
                        <Skeleton />
                    )}

                    {!loading ? (
                        <div className="flex">
                            <Typography className="font-bold">
                                {formatOrdersMessage({ id: 'subscription.qty', defaultMessage: 'Quantity: ' })}
                            </Typography>
                            <Typography className="pl-5">{orderItems?.count?.toString()}</Typography>
                        </div>
                    ) : (
                        <Skeleton className="h-20" />
                    )}
                </div>
                <div className="text-end">
                    {!loading ? (
                        <Link
                            className="inline-block text-md hover:cursor-pointer hover:underline hover:underline-offset-2"
                            link={`/account/?hash=subscriptions&id=subscription_${order?.orderId}`}
                            data-cy="subscription-details"
                        >
                            <Typography>{formatOrdersMessage({ id: 'view.details', defaultMessage: 'View details' })}</Typography>
                        </Link>
                    ) : (
                        <Skeleton className="w-100" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrderItem;
