import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { LineItem } from '@wilm/common';
import Link from 'components/commercetools-ui/atoms/link';
import TrashIcon from 'components/icons/trash';
import { useFormat } from 'helpers/hooks/useFormat';
import { useWishlist } from 'frontastic';
export interface Props {
    item: LineItem;
}

const WishlistItem: FC<Props> = ({ item }) => {
    const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });
    const { data: wishlist, removeLineItem } = useWishlist();

    const url = useMemo(() => {
        const name = item.name;
        const originalUrl = item._url;
        const newSlug = name?.replace(/ /g, '-').toLowerCase() ?? '';
        const newUrl = originalUrl?.replace('slug', newSlug);

        return newUrl;
    }, [item]);

    return (
        <div
            className="border-checkout-border flex justify-between border-b py-20 last-of-type:border-0"
            data-cy={`wishlist-item-${item.variant?.sku}`}
        >
            <div>
                <p className="">{item.name}</p>
                <Link variant="secondary" link={url}>
                    {formatWishlistMessage({ id: 'wishlist.view', defaultMessage: 'View' })}
                </Link>
            </div>
            <button onClick={() => removeLineItem(wishlist!, item)} className="block cursor-pointer">
                <TrashIcon className="w-17" />
            </button>
        </div>
    );
};

export default WishlistItem;
