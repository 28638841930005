import type { FC } from 'react';
import React from 'react';
import type { Order } from '@wilm/common';
import { PaymentMethodType } from '@wilm/common';
import Skeleton from 'react-loading-skeleton';
import Typography from 'components/commercetools-ui/atoms/typography';
import OrderItem from 'components/commercetools-ui/organisms/order-items-listing/order-item';
import Costs from 'components/commercetools-ui/organisms/order-payment-section/components/costs';
import { formatDate } from 'helpers/dateHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCountries } from 'providers/countries';
import useBundle from 'frontastic/hooks/useBundle';
import OrderPaymentItem from './order-payment-item';
import useOrderData from '../helper-hooks/useOrderData';

export interface Props {
    order?: Order;
    asSkeleton?: boolean;
}

const OrderInfoSection: FC<Props> = ({ order, asSkeleton }) => {
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    const { paymentInfo } = useOrderData(order);
    const { countries } = useCountries();

    const { nonBundleItems: lineItems, bundleComponents } = useBundle(order?.lineItems ?? []);

    if (asSkeleton) {
        return (
            <div className="h-fit w-full 2xl:w-3/5">
                <div className="border border-input-disabled p-16 md:p-24 lg:p-30">
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22 mt-30" />
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                </div>
                <div className="mt-30 border border-input-disabled p-16 md:p-24 lg:p-30 2xl:hidden">
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                </div>
            </div>
        );
    } else if (order) {
        const creationDate = order?.createdAt;
        const formattedCreationDate = creationDate && formatDate(creationDate);
        const billingAddress = order.billingAddress!;
        const country = countries?.find(country => country.value === billingAddress.country)?.label;
        return (
            <div className="h-fit w-full 2xl:w-3/5">
                <div className="border border-input-disabled">
                    <div className="border border-x-0 border-t-0 bg-gray-100 p-16 md:p-24 lg:mx-0 lg:p-30">
                        <div className="grid grid-cols-[2fr_1fr]">
                            <div className="grid gap-8">
                                {order?.orderId && (
                                    <div>
                                        <Typography
                                            className="inline text-sm font-bold uppercase text-primary-gray sm:inline-block xl:inline"
                                            data-cy="order-details-order-number-label"
                                        >
                                            {formatOrdersMessage({ id: 'order.number', defaultMessage: 'Order number:' })}
                                        </Typography>{' '}
                                        <Typography
                                            className="inline text-lg text-primary-dark-gray sm:inline-block xl:inline xl:text-md"
                                            data-cy="order-details-order-number"
                                        >
                                            {order.orderId}
                                        </Typography>
                                    </div>
                                )}
                                {formattedCreationDate ? (
                                    <div className="">
                                        <Typography
                                            className="inline text-sm font-bold uppercase text-primary-gray sm:inline-block xl:inline"
                                            data-cy="order-details-order-date-label"
                                        >
                                            {formatOrdersMessage({ id: 'order.created.date', defaultMessage: 'Created date:' })}
                                        </Typography>{' '}
                                        <Typography
                                            className="inline text-lg text-primary-dark-gray sm:inline-block xl:inline xl:text-md"
                                            data-cy="order-details-order-date"
                                        >
                                            {formattedCreationDate}
                                        </Typography>
                                    </div>
                                ) : (
                                    <div></div>
                                )}

                                {order?.paymentState ? (
                                    <div className="">
                                        <Typography
                                            className="inline text-sm font-bold uppercase text-primary-gray sm:inline-block xl:inline"
                                            data-cy="order-details-order-status-label"
                                        >
                                            {formatOrdersMessage({ id: 'payment.status', defaultMessage: 'Payment Status:' })}
                                        </Typography>{' '}
                                        <Typography
                                            className="inline text-lg text-primary-dark-gray sm:inline-block xl:inline xl:text-md"
                                            data-cy="order-details-order-status"
                                        >
                                            {order?.paymentState}
                                        </Typography>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <div className="grid justify-end gap-8">
                                {order?.purchaseOrderNumber ? (
                                    <div className="">
                                        <Typography
                                            className="inline text-sm font-bold uppercase text-primary-gray sm:inline-block xl:inline"
                                            data-cy="order-details-order-status-label"
                                        >
                                            {formatOrdersMessage({ id: 'purchase.order.number', defaultMessage: 'Purchase order number:' })}
                                        </Typography>{' '}
                                        <Typography
                                            className="inline text-lg text-primary-dark-gray sm:inline-block xl:inline xl:text-md"
                                            data-cy="order-details-order-status"
                                        >
                                            {order?.purchaseOrderNumber}
                                        </Typography>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex p-16 md:p-24 lg:mx-0 lg:p-30">
                        <div className="w-1/2">
                            <Typography
                                className="mb-8 whitespace-nowrap text-md font-bold text-secondary-black md:text-base"
                                data-cy="order-details-billing-address-label"
                            >
                                {formatOrdersMessage({
                                    id: 'billing.address',
                                    defaultMessage: 'Billing address:'
                                })}
                            </Typography>
                            <Typography className="mb-8 text-md text-primary-black md:text-base">
                                {`${billingAddress.firstName} ${billingAddress.lastName}`}
                            </Typography>
                            <Typography className="mb-8 text-md text-primary-black md:text-base">
                                {`${billingAddress.streetName}, ${billingAddress.city}`}
                            </Typography>
                            <Typography className="mb-8 text-md text-primary-black md:text-base">{billingAddress.postalCode}</Typography>
                            <Typography
                                className="mb-8 text-md text-primary-black md:text-base"
                                data-cy="order-details-billing-address-country"
                            >
                                {country}
                            </Typography>
                            <Typography className=" text-md text-primary-black md:text-base">{billingAddress.phone}</Typography>
                        </div>
                        <div className="w-1/2">
                            <Typography
                                className="mb-8 whitespace-nowrap text-md font-bold text-secondary-black md:text-base"
                                data-cy="order-details-payment-method-label"
                            >
                                {formatOrdersMessage({
                                    id: 'payment.method',
                                    defaultMessage: 'Payment method:'
                                })}
                            </Typography>

                            <Typography className="text-md text-primary-black md:text-base">
                                {paymentInfo?.type === PaymentMethodType.CARD
                                    ? formatOrdersMessage({ id: 'credit.card', defaultMessage: 'Credit/debit card' })
                                    : ''}
                            </Typography>
                            <Typography
                                className="text-md capitalize text-primary-black md:text-base"
                                data-cy="order-details-payment-method"
                            >
                                {paymentInfo?.label ?? ''}
                            </Typography>
                        </div>
                    </div>

                    <OrderPaymentItem order={order} />
                </div>

                <div className="mt-30 border border-input-disabled p-16 md:p-24 lg:p-30 2xl:hidden">
                    <div className="pb-16">
                        <Typography className="mb-6 font-bold">
                            {formatOrdersMessage({ id: 'your.order', defaultMessage: 'Your order' })}
                        </Typography>
                        {lineItems?.map(lineItem => (
                            <OrderItem
                                key={lineItem.lineItemId}
                                lineItem={lineItem}
                                bundleItems={bundleComponents?.[lineItem.lineItemId]}
                                isLastItem={order?.lineItems?.indexOf(lineItem) === (order?.lineItems?.length ?? 0) - 1}
                            />
                        ))}
                    </div>
                    <Costs order={order} className="border-t" dataReference="order" />
                </div>
            </div>
        );
    }
};

export default OrderInfoSection;
