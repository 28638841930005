import type { FC } from 'react';
import React from 'react';
import type { Order } from '@wilm/common';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { formatDate } from 'helpers/dateHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import Typography from 'components/commercetools-ui/atoms/typography';

export interface Props {
    order: Order;
}
const OrderPaymentItem: FC<Props> = ({ order }) => {
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const paymentInfo = order.payments
        ?.map(payment => {
            const createdDate = new Date(JSON.parse(payment.debug ?? '').createdAt);
            return {
                paymentMethod: payment.paymentMethod,
                createdDate: formatDate(createdDate),
                amount: CurrencyHelpers.formatForCurrency(payment?.amountPlanned),
                paymentStatus: payment.transactionStatus,
                id: payment.id
            };
        })
        .filter(p => p.paymentStatus !== '' && p.paymentMethod?.toLowerCase() !== 'invoice');

    if (!paymentInfo?.length) {
        return null;
    }

    return (
        <div className="p-16 md:p-24 lg:mx-0 lg:p-30">
            <Typography className="mb-8 whitespace-nowrap text-lg font-bold text-secondary-black" data-cy="order-details-payments-label">
                {formatCommonMessage({
                    id: 'payments',
                    defaultMessage: 'Payments'
                }) + ':'}
            </Typography>
            <div className="grid w-full gap-y-8 md:grid-cols-[3fr,3fr,5fr]">
                {/* Header */}
                <div className="text-left font-semibold">{formatCommonMessage({ id: 'date', defaultMessage: 'Date' })}:</div>
                <div className="text-left font-semibold">{formatCommonMessage({ id: 'amount', defaultMessage: 'Amount' })}:</div>
                <div className="text-left font-semibold">{formatCommonMessage({ id: 'status', defaultMessage: 'Status' })}:</div>

                {/* Payment Rows */}
                {paymentInfo?.map(payment => (
                    <React.Fragment key={payment.id}>
                        <div className="col-span-3 my-2 border-t border-neutral-400"></div>
                        <div>{payment.createdDate}</div>
                        <div>{payment.amount}</div>
                        <div>{payment.paymentStatus}</div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default OrderPaymentItem;
