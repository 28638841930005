import type { FC } from 'react';
import React from 'react';
import type { Order } from '@wilm/common';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import useSubscriptionsFetch from './helper-hooks/useSubscriptionsFetch';
import SubscriptionItem from './SubscriptionItem';

interface Props {
    staticText?: string;
}

const Subscriptions: FC<Props> = ({ staticText }) => {
    const { ordersSubscription, loading } = useSubscriptionsFetch();
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

    return (
        <>
            <Typography as="h2" className="ml-15 mt-22 text-18 text-primary-black md:ml-24 md:text-22 lg:ml-44 lg:mt-42">
                {formatOrdersMessage({
                    id: 'subscription.list',
                    defaultMessage: 'Subscription list'
                })}
            </Typography>

            {staticText && (
                <div className="mb-5 mt-15 px-16 md:mt-20 md:px-24 lg:mb-18 lg:px-44">
                    <Typography as="h3" className="text-md text-secondary-black md:text-16">
                        {staticText}
                    </Typography>
                </div>
            )}

            <div className="overflow-auto px-15 pt-15 md:px-24 lg:px-44">
                {!loading ? (
                    ordersSubscription?.map((order?: Order) => <SubscriptionItem key={order?.orderId} order={order} loading={loading} />)
                ) : (
                    <SubscriptionItem loading={loading} />
                )}
            </div>
        </>
    );
};

export default Subscriptions;
