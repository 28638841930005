import React, { useContext, useMemo, useCallback, useState, useEffect } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { Order } from '@wilm/common';
import type { SWRResponse } from 'swr';
import useSWR from 'swr';
import { sdk } from 'sdk';

const OrderHistoryContext = React.createContext(
    {} as {
        loading: boolean;
        selectedOrder: Order | undefined;
        fullOrderHistory: FullOrderHistory | undefined;
        currentPage: number;
        totalPages: number;
        moveToPageHandler: (page: number) => void;
        selectOrder: (params: { order?: Order; orderId?: string }) => void;
        clearSelectedOrder: () => void;
    }
);

const OFFSET_DEFAULT_VALUE = 0;
const LIMIT_DEFAULT_VALUE = 16;

interface OrderHistoryProviderProps {
    orderNumber?: string;
    children?: React.ReactNode;
}

interface FullOrderHistory {
    orders: Order[];
    limit: number;
    total: number;
    offset: number;
}

const OrderHistoryProvider: React.FC<OrderHistoryProviderProps> = ({ orderNumber, children }) => {
    const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(undefined);
    const router = useRouter();
    const searchParams = useSearchParams();
    console.info('---> searchParams', searchParams);

    const [pagination, setPagination] = useState({
        offset: OFFSET_DEFAULT_VALUE
    });

    const ordersFetcher = (offset?: number) => {
        let newOffset = pagination.offset;

        if (typeof offset === 'number') {
            newOffset = offset;
        }

        return sdk.callAction<FullOrderHistory>({
            actionName: 'cart/getOrders',
            payload: { offset: newOffset, limit: LIMIT_DEFAULT_VALUE }
        });
    };

    const orderFetcher = () =>
        sdk.callAction<FullOrderHistory>({
            actionName: 'cart/getOrders',
            payload: { orderNumber }
        });
    const orderResponse: SWRResponse<SDKResponse<FullOrderHistory>> = useSWR<SDKResponse<FullOrderHistory>>(
        'action/cart/getOrder',
        orderFetcher,
        { revalidateOnMount: true }
    );

    useEffect(() => {
        if (orderNumber && !orderResponse.isValidating && orderResponse.data && !orderResponse.data?.isError) {
            const order = orderResponse.data.data.orders[0];
            if (order) {
                selectOrder({ order });
            }
        }
    }, [orderResponse.data]);

    const fullOrderHistoryResponse: SWRResponse<SDKResponse<FullOrderHistory>> = useSWR<SDKResponse<FullOrderHistory>>(
        'action/cart/getOrders',
        ordersFetcher,
        { revalidateOnMount: true }
    );

    const loading = useMemo(() => fullOrderHistoryResponse.isValidating, [fullOrderHistoryResponse.isValidating]);

    useEffect(() => {
        if (loading) return;
        const orderId = searchParams.get('id')?.split('_')[1];
        if (!orderId) {
            clearSelectedOrder();
        }

        if (orderId) {
            selectOrder({ orderId });
        }
    }, [searchParams, loading]);

    const fullOrderHistory = useMemo(
        () => (fullOrderHistoryResponse.data?.isError ? undefined : fullOrderHistoryResponse.data?.data),
        [fullOrderHistoryResponse.data]
    );

    const { currentPage, totalPages } = useMemo(() => {
        const pagination = {
            currentPage: 1,
            totalPages: 0
        };
        if (fullOrderHistory) {
            pagination.currentPage = Math.ceil(fullOrderHistory.offset / fullOrderHistory.limit) + 1;
            pagination.totalPages = Math.ceil(fullOrderHistory.total / fullOrderHistory.limit) ?? 1;
        }
        return pagination;
    }, [fullOrderHistory]);

    const findOrder = useCallback(
        (orderId: string) => {
            return fullOrderHistory?.orders.find(order => order.orderId === orderId);
        },
        [fullOrderHistory]
    );

    const selectOrder = useCallback(
        (params: { order?: Order; orderId?: string }) => {
            const orderId = params.orderId;
            let order = params.order;

            if (!order) {
                order = findOrder(orderId ?? '');
            }

            if (order) {
                setSelectedOrder(order);
                router.push('/account/?hash=orders&id=order_' + order.orderId, { scroll: false });
                return;
            }
            router.push('/account/?hash=orders', { scroll: false });
        },
        [router, findOrder]
    );

    const clearSelectedOrder = useCallback(() => {
        setSelectedOrder(undefined);
        router.push('/account/?hash=orders', { scroll: false });
    }, [router]);

    const moveToPageHandler = useCallback(
        (page: number) => {
            if (!loading) {
                const newPagination = { ...pagination };
                newPagination.offset = (page - 1) * LIMIT_DEFAULT_VALUE;
                setPagination(newPagination);
                void fullOrderHistoryResponse.mutate(ordersFetcher(newPagination.offset));
                window?.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
        [loading, pagination, fullOrderHistoryResponse]
    );

    const value = useMemo(
        () => ({
            loading,
            selectedOrder,
            currentPage,
            totalPages,
            selectOrder,
            fullOrderHistory,
            moveToPageHandler,
            clearSelectedOrder
        }),
        [loading, selectedOrder, currentPage, totalPages, selectOrder, fullOrderHistory, moveToPageHandler, clearSelectedOrder]
    );
    return <OrderHistoryContext.Provider value={value}>{children}</OrderHistoryContext.Provider>;
};

export default OrderHistoryProvider;

export const useOrderHistory = () => useContext(OrderHistoryContext);
