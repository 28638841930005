import React from 'react';
import type { Address as AddressType } from '@wilm/common';
import Radio from 'components/commercetools-ui/atoms/radio';
import Typography from 'components/commercetools-ui/atoms/typography';
import type { TypographyProps } from 'components/commercetools-ui/atoms/typography/types';
import { useFormat } from 'helpers/hooks/useFormat';
import usePropsToAddressType from './mapPropsToAddressType';
import EditCTA from '../../account-atoms/edit-cta';

export interface AddressProps {
    address: AddressType;
    isDefaultAddress?: boolean;
    isChecked: boolean;
    selectAddress: (address: AddressType) => void;
}

const Address: React.FC<AddressProps> = ({ address, isDefaultAddress, selectAddress }) => {
    const { mapPropsToAddress } = usePropsToAddressType();
    const { label } = mapPropsToAddress(address);

    const { formatMessage } = useFormat({ name: 'account' });

    const addressInfoTypographyProps: TypographyProps = {
        className: 'text-md lg:text-base mb-4'
    };

    const addressInfoTypographyElements = [
        `${address.firstName} ${address.lastName}`,
        address.streetName,
        `${address.postalCode} ${address.city}`
    ];

    return (
        <div className="border-checkout-border relative flex items-center justify-between rounded-md border px-20 py-15 md:py-29 md:pl-34 md:pr-40">
            <div className="flex items-center gap-28">
                <button
                    className="absolute left-0 top-0 size-full"
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        selectAddress(address);
                    }}
                ></button>
                <Radio
                    className="grid cursor-pointer"
                    inputClassName="cursor-pointer"
                    id={address.addressId}
                    name={address.addressId}
                    value={address.addressId}
                    checked={isDefaultAddress}
                    onChange={() => selectAddress(address)}
                />
                <div className="grid">
                    <div className="flex">
                        <Typography className="mb-3 text-md font-bold capitalize lg:text-base">{label}</Typography>
                        {isDefaultAddress && (
                            <Typography className="ml-5 font-bold">
                                {formatMessage({ id: 'default.address', defaultMessage: '(default address)' })}
                            </Typography>
                        )}
                    </div>

                    <div className="flex flex-col items-start">
                        {addressInfoTypographyElements.map(element => (
                            <Typography key={element} {...addressInfoTypographyProps}>
                                {element}
                            </Typography>
                        ))}
                    </div>
                </div>
            </div>

            <EditCTA editHref={`?hash=addresses&id=address_${address.addressId}`} />
        </div>
    );
};

export default Address;
